import * as React from "react";
import { Button, FormControl, View, Text } from "native-base";
import styles from "./styles";
import { SecondStep, Geo } from "../../../assets/images/svg";
// import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { useCallback, useEffect, useRef, useState } from "react";
// import { GOOGLE_API_KEY } from "../../../config";
// import MapView, {Marker} from 'react-native-maps';
// import MapViewDirections from 'react-native-maps-directions';
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useDispatch, useSelector } from "react-redux";
import BottomNavigator from "../../../components/BottomNavigator";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_API_KEY } from "../../../config";
import {
  APIProvider,
  Map,
  Marker,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import ErrorBanner from "../../../components/Banners/errorBanner";

export default function NOFSecondStepScreen({ route, navigation }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pickUpLocationCoordinates, setPickUpLocationCoordinates] = useState(
    {}
  );
  const [errorMessage, setErrorMessage] = useState("");
  const position = { lat: 40.6973709, lng: -74.1444872 };
  const pickUpLocation = useSelector((state) => state.order.pickUpLocation);
  const deliveryLocation = useSelector((state) => state.order.deliveryLocation);
  const [deliveryLocationCoordinates, setDeliveryLocationCoordinates] =
    useState({});
  // const ref = useRef();

  const nextStep = () => {
    if (validateLocations()) {
      navigate("/create/3");
    }
  };

  const prevStep = () => {
    navigate("/create/1");
  };

  const handleChange = (key, value) => {
    dispatch(setInputValue({ key, value }));
    setErrorMessage("");
  };

  const validateLocations = () => {
    if (!pickUpLocation || !deliveryLocation) {
      setErrorMessage("Please fill in both pick-up and delivery locations.");
      return false;
    }
    return true;
  };

  const Directions = () => {
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionService] = useState();
    const [directionsRenderer, setDirectionsRenderer] = useState();
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
      if (!routesLibrary || !map) return;
      setDirectionService(new routesLibrary.DirectionsService());
      setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map, pickUpLocation, deliveryLocation]);

    useEffect(() => {
      if (!directionsService || !directionsRenderer) return;
      const dirSer = new routesLibrary.DirectionsService();
      const dirRenderer = new routesLibrary.DirectionsRenderer({ map });
      // dirService
      dirSer
        .route({
          origin: pickUpLocation,
          destination: deliveryLocation,
          travelMode: google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: false,
        })
        .then((response) => {
          dirRenderer.setDirections(response);
          setRoutes(response.routes);
        });
    }, [directionsService, directionsRenderer]);
  };

  const MapD = () => {
    return (
      <APIProvider
      // apiKey={GOOGLE_API_KEY}
      >
        <Map
          style={{ height: 400, width: "100%" }}
          defaultCenter={
            pickUpLocationCoordinates.lng ? pickUpLocationCoordinates : position
          }
          defaultZoom={10}
        >
          <Directions />
        </Map>
      </APIProvider>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.stepper}>
        <SecondStep />
      </View>
      {errorMessage && <ErrorBanner message={errorMessage} />}

      <View keyboardShouldPersistTaps={"handled"}>
        <View style={styles.divider} />
        <FormControl.Label>Pick Up Location</FormControl.Label>
        <View style={styles.dateButtonContainer}>
          <Geo />
          <Autocomplete
            style={{
              fontSize: 14,
              width: "100%",
              border: 0,
              paddingLeft: 14,
              outline: "none",
              marginBottom: 8,
            }}
            placeholder={pickUpLocation || "Search"}
            onPlaceSelected={(details) => {
              const pickLocation = JSON.parse(
                JSON.stringify(details?.geometry?.location)
              );
              pickLocation.title = "Pick Up Location";
              setPickUpLocationCoordinates(pickLocation);
              handleChange("pickUpLocation", details?.formatted_address);
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "us" },
            }}
          />
        </View>
        <View style={styles.divider} />
        <FormControl.Label>Delivery Location</FormControl.Label>
        <View style={styles.dateButtonContainer}>
          <Geo />
          <Autocomplete
            style={{
              fontSize: 14,
              width: "100%",
              border: 0,
              paddingLeft: 14,
              outline: "none",
              marginBottom: 8,
            }}
            placeholder={deliveryLocation || "Search"}
            onPlaceSelected={(details) => {
              const location = JSON.parse(
                JSON.stringify(details?.geometry?.location)
              );
              location.title = "Delivery Location";
              setDeliveryLocationCoordinates(location);
              handleChange("deliveryLocation", details?.formatted_address);
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "us" },
            }}
          />
        </View>
        <View style={styles.divider} />
        {!!pickUpLocation?.length && <MapD />}

        <View style={styles.emptySpace} />
        <View stylne={styles.emptySpace} />
        <BottomNavigator
          nextOnPress={nextStep}
          nextText={"Next"}
          prevOnPress={prevStep}
          prevText={"Previous"}
        />
      </View>
    </View>
  );
}
