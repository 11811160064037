// export const formatDate = date => {
//   if (typeof date === 'string') {
//     return date;
//   }
//   return `${date?.getMonth() + 1}/${date?.getDate()}/${date?.getFullYear()}`;
// };

// export const formatStringToDate = dateString => {
//   const date = new Date(dateString);

//   return `${date?.getMonth() + 1 || '00'}/${date?.getDate() || '00'}/${
//     date?.getFullYear() || '00'
//   }`;
// };

export const formatStringToDate = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateString + "T00:00:00Z");
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};
