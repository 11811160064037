import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  imagesRow: {
    marginTop: 10,
    gap: 8,
  },
  image: {
    borderRadius: 8,
    height: 200,
  },
  imageSmall: {
    borderRadius: 8,
    height: 50,
    width: 80,
    marginRight: 8,
  },
  header: {
    color: "#171717",
    fontSize: 20,
    fontWeight: "700",
    marginTop: 20,
  },
  badges: {
    flexDirection: "row",
    marginTop: 10,
  },
  badge: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#DAF8EA",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    borderRadius: 8,
  },
  badgeText: {
    color: "#1DCE80",
    fontSize: 14,
    marginLeft: 8,
  },
  vinBadge: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: "row",
    borderRadius: 10,
    backgroundColor: "#fff",
    marginTop: 10,
  },
  headerBlock: {
    color: "#171717",
    fontSize: 16,
    fontWeight: "700",
    marginTop: 20,
    marginBottom: 10,
  },
  recipientRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 7,
  },
  vinText: {
    marginLeft: 12,
  },
  greyText: {
    fontWeight: "500",
    fontSize: 14,
    color: "#737373",
  },
  locationWrapper: {
    flexDirection: "row",
    marginTop: 12,
  },
  locationTextWrapper: {
    marginLeft: 12,
  },
  locationText: {
    color: "#737373",
    fontSize: 14,
    fontWeight: "500",
  },
  dateRow: {
    flexDirection: "row",
  },
  calendarIcon: {
    marginRight: 8,
  },
  button: {
    marginTop: 16,
  },
  deleteButton: {
    marginTop: 16,
    backgroundColor: "#B91C1C",
  },
  blockWrapper: {
    borderTopWidth: 1,
    paddingBottom: 8,
    marginTop: 24,
    borderColor: "#D4D4D4",
  },

  divider: {
    height: 100,
  },
  buttonsRow: {
    flexDirection: "row",
    flex: 2,
  },
  cancel: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    marginRight: 3,
  },
  acButton: {
    flex: 1,
    marginLeft: 3,
  },
  dcButton: {
    flex: 1,
    marginLeft: 3,
    backgroundColor: "#B91C1C",
  },
  status: {
    flexDirection: "row",
    marginBottom: 15,
  },
  row: {
    flexDirection: "row",
    gap: 5,
  },
  sendOrderButton: {
    backgroundColor: "#FFA500", 
    marginTop: 16,
  },
});

export default styles;
