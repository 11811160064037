import * as React from "react";
import styles from "./styles";
import { Button, View } from "native-base";

export default function BottomNavigator({
  prevText,
  nextText,
  prevOnPress,
  nextOnPress,
}) {
  return (
    <View style={styles.wrapper}>
      <Button
        variant="outline"
        colorScheme="secondary"
        style={[styles.button]}
        onPress={prevOnPress}
      >
        {prevText}
      </Button>
      <Button style={[styles.button, { width: 96 }]} onPress={nextOnPress} >
        {nextText}
      </Button>
    </View>
  );
}
