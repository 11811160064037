import * as React from "react";
import {
  // ScrollView,
  Text,
  View,
  AlertDialog,
  Button,
  Input,
  useToast,
  Center,
  Pressable,
} from "native-base";
import styles from "./styles";

import {
  Building,
  // Card,
  Eye,
  EyeSlash,
  Lock,
  Logout,
  Person,
  Trash,
} from "../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { globalLogout } from "../../networking/globalActions";
import { useState } from "react";
import instance from "../../networking/axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config";

export default function MyProfileScreen() {
  const navigate = useNavigate();
  const editProfile = () => {
    navigate("/profile/edit");
  };

  const isCompany = false;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [password, setPassword] = useState("");
  const { fullName, email, phone, address } = useSelector(
    (state) => state.profile
  );
  const cancelRef = React.useRef(null);
  const toast = useToast();
  const onClose = () => {
    if (isDeleted) {
      onDeleted();
    } else {
      setIsOpen(false);
    }
  };
  const onDelete = () => {
    // setIsDeleted(true);
    instance
      .delete(`${API_URL}/auth/profile`, {
        data: {
          password,
        },
      })
      .then((res) => {
        toast.show({
          description: "Deleted",
          placement: "top",
        });
        setIsDeleted(true);
      })
      .catch((err) => {
        console.log("err", err);
        toast.show({
          description: "Wrong password",
          placement: "top",
        });
      });
  };

  const logOut = () => {
    dispatch(globalLogout());
    navigate("/");
  };

  const onDeleted = () => {
    dispatch(logOutAction());
    navigate("/");
  };
  const showPassword = () => {
    setHidden(!hidden);
  };
  const [isDeleted, setIsDeleted] = React.useState(false);

  return (
    <Center style={styles.center}>
      <View style={styles.container}>
        <View style={styles.subHeaderWrapper}>
          <Text style={styles.myProfileTitle}>My Profile</Text>
          <Text style={styles.actionText} onPress={editProfile}>
            Edit
          </Text>
        </View>
        {!isCompany && (
          <View style={styles.infoContainer}>
            <View style={[styles.row, { marginBottom: 12 }]}>
              <Person style={styles.icon} />
              <Text style={styles.rowHeader}>Profile info</Text>
            </View>
            <Text style={styles.rowHeader}>Full Name</Text>
            <Text style={styles.rowText}>{fullName}</Text>
            <View style={styles.divider} />
            <Text style={styles.rowHeader}>Email</Text>
            <Text style={styles.rowText}>{email}</Text>
            <View style={styles.divider} />
            <Text style={styles.rowHeader}>Phone</Text>
            <Text style={styles.rowText}>{phone}</Text>
            <View style={styles.divider} />
            <Text style={styles.rowHeader}>Address</Text>
            <Text style={styles.rowText}>
              {address?.streetAddressLine1} {address?.city}, {address.state}{" "}
              {address.zip}
            </Text>
          </View>
        )}
        {isCompany && (
          <View style={styles.infoContainer}>
            <View mb={"10px"} style={styles.row}>
              <Building />
              <Text style={styles.header}>Company information</Text>
            </View>

            <Text style={styles.rowHeader}>Company Name</Text>
            <Text style={styles.rowText}>DispatchFull</Text>
            <View style={styles.divider} />

            <Text style={styles.rowHeader}>License Number</Text>
            <Text style={styles.rowText}>1222 2323 1221</Text>
            <View style={styles.divider} />

            <Text style={styles.rowHeader}>Email</Text>
            <Text style={styles.rowText} />
            <View style={styles.divider} />

            <Text style={styles.rowHeader}>Phone</Text>
            <Text style={styles.rowText}>+1 (000)00000000</Text>
            <View style={styles.divider} />

            <Text style={styles.rowHeader}>Address</Text>
            <Text style={styles.rowText}>
              One Apple Park Way Cupertino, CA 95014
            </Text>
            <View style={styles.divider} />
            <View mt={"16px"} mb={"10px"} style={styles.row}>
              <Person />
              <Text style={styles.header}>Contact person</Text>
            </View>

            <Text style={styles.rowHeader}>Full Name</Text>
            <Text style={styles.rowText} />
            <View style={styles.divider} />
            <Text style={styles.rowHeader}>Position</Text>
            <Text style={styles.rowText}>CFO</Text>
            <View style={styles.divider} />
            <Text style={styles.rowHeader}>Email</Text>
            <Text style={styles.rowText} />
            <View style={styles.divider} />
            <Text style={styles.rowHeader}>Phone</Text>
            <Text style={styles.rowText}>+1 (000)00000000</Text>
          </View>
        )}
        <Pressable
          onPress={() => setIsOpen(!isOpen)}
          style={styles.infoContainer}
        >
          <View style={styles.row}>
            <Trash style={styles.icon} />
            <Text style={styles.rowHeader}>Delete account</Text>
          </View>
        </Pressable>
        <Pressable onPress={logOut} style={styles.infoContainer}>
          <View style={styles.row}>
            <Logout style={styles.icon} />
            <Text style={styles.rowHeader}>Log out</Text>
          </View>
        </Pressable>
        <View style={styles.emptySpace} />

        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <AlertDialog.Content>
            <AlertDialog.CloseButton />
            <AlertDialog.Header>Delete Account</AlertDialog.Header>
            <AlertDialog.Body>
              {isDeleted
                ? "Your account successfully deleted"
                : "Are you sure you want to delete your account? This action is " +
                  "irreversible and will result in the permanent deletion of all your " +
                  "account information, including your posts, comments, and personal " +
                  "settings. Once deleted, your profile cannot be recovered"}
              {!isDeleted && (
                <>
                  <Text style={styles.confirmDeletionText}>
                    Please enter your password to confirm deletion
                  </Text>
                  <Input
                    type={hidden ? "password" : "text"}
                    value={password}
                    onChangeText={setPassword}
                    InputLeftElement={
                      <View style={styles.leftIcon}>
                        <Lock />
                      </View>
                    }
                    InputRightElement={
                      <Pressable
                        onPress={showPassword}
                        style={styles.rightIcon}
                      >
                        {hidden ? <EyeSlash /> : <Eye />}
                      </Pressable>
                    }
                    placeholder="Password"
                  />
                </>
              )}
            </AlertDialog.Body>
            <AlertDialog.Footer>
              {isDeleted ? (
                <Button style={styles.deleteButton} onPress={onDeleted}>
                  Ok
                </Button>
              ) : (
                <View style={styles.buttonsRow}>
                  <Button
                    style={styles.cancel}
                    variant="unstyled"
                    colorScheme="coolGray"
                    onPress={onClose}
                    ref={cancelRef}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={styles.deleteButton}
                    colorScheme="danger"
                    onPress={onDelete}
                  >
                    Delete
                  </Button>
                </View>
              )}
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      </View>
    </Center>
  );
}
