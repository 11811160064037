import React from "react";
import { useEffect } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ErrorBanner({ message }) {
  useEffect(() => {
    if (message) {
      toast.error(message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
        style: {marginTop: "55px"},
        transition: Slide,
      });
    }
  }, [message]);

  return <ToastContainer />;
}
