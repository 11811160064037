import { StyleSheet } from "react-native";
import { headerHeight } from "../../../config/constants";

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: (window.innerHeight - 600) / 2 - headerHeight > 20 ?  (window.innerHeight - 600) / 2 - headerHeight : 20,
  },
  forgotPassword: {
    marginLeft: "auto",
    marginTop: 4,
  },
  container: {
    padding: 40,
    flex: 1,
    justifyContent: "center",
    width: 415,
    backgroundColor: "#FAFAFA",
    borderRadius: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.22,
    elevation: 5,
  },
  changePage: {
    paddingBottom: 30,
    marginTop: 10,
  },
  actionText: {
    fontWeight: "600",
    color: "#1DCE80",
  },
  leftIcon: {
    marginLeft: 12,
  },
  rightIcon: {
    marginRight: 12,
  },
  button: {
    marginTop: "auto",
  },
  divider: {
    height: 16,
  },
  row: {
    flexDirection: "row",
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
  },
  dateButtonContainer: {
    flex: 1,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#E5E5E5",
    flexDirection: "row",
    paddingTop: 4,
    paddingRight: 12,
    paddingLeft: 12,
    paddingBottom: 4,
    alignItems: "center",
  },
});

export default styles;
