import * as React from "react";
import {
  Button,
  FormControl,
  View,
  Text,
  Center,
  Checkbox,
  Input,
} from "native-base";
import styles from "./styles";
import { useEffect, useState } from "react";
import { ThirdStep, Year } from "../../../assets/images/svg";
import { formatDate } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useNavigate } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import ErrorBanner from "../../../components/Banners/errorBanner";


export default function NOFThirdStepScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const date = new Date();
  const nextStep = () => {
    if (validateDates()) {
      navigate("/create/4");
    }
  };

  const prevStep = () => {
    navigate("/create/2");
  };

  const [calendar, setCalendar] = useState("");
  const [open, setOpen] = useState(false);
  const flexibleDates = useSelector((state) => state.order.flexibleDates);
  const pickUpDate1 = useSelector((state) => state.order.pickUpDate1);
  const deliveryDate1 = useSelector((state) => state.order.deliveryDate1);
  const [pickUpDateError, setPickUpDateError] = useState(false);
  const [deliveryDateError, setDeliveryDateError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateDates = () => {
    let valid = true;
    if (!pickUpDate1) {
      setPickUpDateError(true);
      valid = false;
    }
    if (!deliveryDate1 || (pickUpDate1 && deliveryDate1 < pickUpDate1)) {
      setDeliveryDateError(true);
      valid = false;
    }
    if (!valid) {
      setErrorMessage("Please fill in the required dates.");
    }
    return valid;
  };

  // useEffect(() => {
  //   if (!pickUpDate1) {
  //     handleChange("pickUpDate1", new Date());
  //   }
  //   if (!deliveryDate1) {
  //     handleChange("deliveryDate1", new Date());
  //   }
  // }, []);

  const handleChange = (key, value) => {
    dispatch(setInputValue({ key, value }));
    if (key === "pickUpDate1") setPickUpDateError(false);
    if (key === "deliveryDate1") setDeliveryDateError(false);
  };

  return (
    <View style={styles.container}>
      <View style={styles.stepper}>
        <ThirdStep />
      </View>
      {errorMessage && <ErrorBanner message={errorMessage} />}
      <View>
        <View style={styles.divider} />
        <FormControl.Label>Pick Up Date</FormControl.Label>
        <View style={styles.dateRow}>
          <View style={styles.inputBorder}>
            <input
              style={{
                borderWidth: 0,
                color: "#A3A3A3",
              }}
              onChange={(e) => {
                const selectedDate = e.target.value;
                const today = new Date().toISOString().split("T")[0];

                // Check if the selected date is before today
                if (selectedDate >= today) {
                  handleChange("pickUpDate1", selectedDate);
                  // Check if the selected pickup date is later than the delivery date
                  if (selectedDate > deliveryDate1) {
                    handleChange("deliveryDate1", ""); // Reset delivery date
                  }
                } else {
                  alert("Please select a valid date (today or later).");
                }
              }}
              value={pickUpDate1 || ""}
              type="date"
              name="pickUpDate1"
              min={new Date().toISOString().split("T")[0] || deliveryDate1}
            />
          </View>
        </View>

        <View style={styles.divider} />

        <FormControl.Label>Delivery Date</FormControl.Label>
        <View style={styles.dateRow}>
          <View style={styles.inputBorder}>
            <input
              style={{
                borderWidth: 0,
                color: "#A3A3A3",
              }}
              onChange={(e) => {
                const selectedDate = e.target.value;
                const today = new Date().toISOString().split("T")[0];

                // Check if the selected date is after or equal to pickUpDate1
                if (pickUpDate1 && selectedDate >= pickUpDate1) {
                  handleChange("deliveryDate1", selectedDate);
                } else if (!pickUpDate1 && selectedDate >= today) {
                  handleChange("deliveryDate1", selectedDate);
                } else {
                  alert(
                    "Please select a valid delivery date after the pick-up date."
                  );
                }
              }}
              value={deliveryDate1 || ""}
              type="date"
              name="deliveryDate1"
              min={pickUpDate1 || new Date().toISOString().split("T")[0]}
            />
          </View>
        </View>
        {/* <View style={styles.row}>
          <Center>
            <Checkbox
              value="checkbox"
              defaultIsChecked={flexibleDates}
              onChange={(state) => {
                handleChange("flexibleDates", state);
              }}
              accessibilityLabel="checkbox"
            />
          </Center>

          <Text style={styles.textContainer} fontSize="sm">
            Flexible Dates
          </Text>
        </View> */}

        <View style={styles.emptySpace} />
      </View>
      {/*<DatePicker*/}
      {/*  modal*/}
      {/*  minimumDate={pickUpDate1 || new Date()}*/}
      {/*  mode={"date"}*/}
      {/*  date={date}*/}
      {/*  open={open}*/}
      {/*  onConfirm={(date) => {*/}
      {/*    setOpen(false);*/}
      {/*    setDate(date);*/}
      {/*  }}*/}
      {/*  onCancel={() => {*/}
      {/*    setOpen(false);*/}
      {/*  }}*/}
      {/*/>*/}

      <View style={styles.emptySpace} />
      <BottomNavigator
        nextOnPress={nextStep}
        nextText={"Next"}
        prevOnPress={prevStep}
        prevText={"Previous"}
      />
    </View>
  );
}
