import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    vin: "",
    additionalEquipment: "",
    hasCustom: false,
    havePersonal: false,
    flexibleDates: false,
    images: [],
    brand: "",
    model: "",
    year: "",
    // length: '',
    // height: '',
    // width: '',
    customChanges: '',
    personalItems: '',
    // pickUpLocation: '',
    // deliveryLocation: '',
    // pickUpDate1: '',
    // deliveryDate1: '',
    // inoperable: null,
    // trailerType: '',
    isInoperable: false,
    isTrailerEnclosed: false,
    // recipientContactName: '',
    // recipientContactPhone: '',
    // recipientContactEmail: '',
    bidAmount: "",
    carrierEmail: "",
    carrierName: "",
    carrierRating: "",
    carrierPhone: "",
    carrierSince: "",
    numberOfTrucks: "",
  },
  reducers: {
    setInputValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    clearInputValues: (state) => {
      Object.keys(state).forEach((key) => {
        if (typeof state[key] === "boolean") {
          state[key] = false; // Reset boolean values to false
        } else if (Array.isArray(state[key])) {
          state[key] = []; // Reset arrays to empty arrays
        } else {
          state[key] = ""; // Reset other types (e.g., strings) to empty strings
        }
      });
    },
  },
});

export const { setInputValue, clearInputValues } = orderSlice.actions;
export const selectInputValues = (state) => state.order;
export default orderSlice.reducer;
