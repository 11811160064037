import {createAsyncThunk} from '@reduxjs/toolkit';
import {API_URL} from '../../config';
import instance from '../axios';

export const createOrder = createAsyncThunk(
  'order/create',
  async ({data}, {rejectWithValue}) => {
    try {
      const res = await instance.post(`${API_URL}/orders`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const sendOrderToTMS = createAsyncThunk(
  'order/sendToTMS',
  async ({data}, {rejectWithValue}) => {
    try {
      const res = await instance.post(`${API_URL}/orders/sendToTMS`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const getOrderBids = createAsyncThunk(
  'bids/get',
  async ({id}, {rejectWithValue}) => {
    try {
      const res = await instance.get(`${API_URL}/orders/${id}/bids`);
      return res.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.response);
    }
  },
);

export const getInfoByVinNumber = createAsyncThunk(
  'vin/get',
  async ({vin}, {rejectWithValue}) => {
    try {
      const res = await instance.get(`${API_URL}/orders/vin/${vin}`);
      return res.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.response);
    }
  },
);

export const deleteOrder = createAsyncThunk(
  'order/delete',
  async ({id}, {rejectWithValue}) => {
    try {
      const res = await instance.delete(`${API_URL}/orders/${id}`);
      return res.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.response);
    }
  },
);

export const declineBid = createAsyncThunk(
  'bids/decline',
  async ({data}, {rejectWithValue}) => {
    try {
      const res = await instance.post(`${API_URL}/bids/${data.bidID}`, data);
      return res.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.response);
    }
  },
);

export const disputeOrder = createAsyncThunk(
  'order/dispute',
  async ({data}, {rejectWithValue}) => {
    try {
      const res = await instance.post(
        `${API_URL}/orders/dispute/${data.id}`,
        data,
      );
      return res.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.response);
    }
  },
);

export const completeOrder = createAsyncThunk(
  'order/complete',
  async ({data}, {rejectWithValue}) => {
    try {
      const res = await instance.post(
        `${API_URL}/orders/complete/${data.id}`,
        data,
      );
      return res.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.response);
    }
  },
);

export const rateOrder = createAsyncThunk(
  'order/rate',
  async ({data}, {rejectWithValue}) => {
    try {
      const res = await instance.post(
        `${API_URL}/orders/rate/${data.id}`,
        data,
      );
      return res.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.response);
    }
  },
);

export const acceptBid = createAsyncThunk(
  'order/rate',
  async ({data}, {rejectWithValue}) => {
    try {
      const res = await instance.post(
        `${API_URL}/bids/accept/${data.id}`,
        data,
      );
      return res.data;
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.response);
    }
  },
);
