import * as React from "react";
import {
  Button,
  Center,
  Checkbox,
  FormControl,
  Input,
  Text,
  View,
} from "native-base";
import styles from "./styles";
import { Email, FourthStep, Phone, User } from "../../../assets/images/svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { carsObj } from "../../../assets/data/carsList";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useNavigate } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import PhoneInput from "react-phone-number-input/input";
import ErrorBanner from "../../../components/Banners/errorBanner";

export default function NOFFourthStepScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  const checkboxRecipient = useSelector(
    (state) => state.order.checkboxRecipient
  );
  const email = useSelector((state) => state.profile.email);
  const fullName = useSelector((state) => state.profile.fullName);
  const phone = useSelector((state) => state.profile.phone);
  const nextStep = () => {
    if (
      !recipientContactName ||
      !recipientContactEmail ||
      !recipientContactPhone
    ) {
      setErrorMessage(
        "Please fill in the full name, email, and phone number before proceeding."
      );
      return;
    } else {
      setErrorMessage("");
    }

    if (!recipientContactEmail.includes("@") || !recipientContactEmail.includes(".")) {
      setErrorMessage("Email is invalid");
      return;
    } else {
      setErrorMessage("");
    }

    const isValidPhone = recipientContactPhone && recipientContactPhone.replace(/\D/g, "").length === 11;

      if (!isValidPhone) {
        setErrorMessage("Please enter a valid 10-digit phone number.");
        return;
      } else {
        setErrorMessage("");
      }

    navigate("/create/preview");
  };

  const prevStep = () => {
    navigate("/create/3");
  };

  const recipientContactName = useSelector(
    (state) => state.order.recipientContactName
  );
  const recipientContactPhone = useSelector(
    (state) => state.order.recipientContactPhone
  );
  const recipientContactEmail = useSelector(
    (state) => state.order.recipientContactEmail
  );

  return (
    <View style={styles.container}>
      {errorMessage && <ErrorBanner message={errorMessage} />}
      <View style={styles.stepper}>
        <FourthStep />
      </View>
      <View>
        <View style={styles.divider} />

        <FormControl.Label>Recipient Name</FormControl.Label>
        <View style={styles.row}>
          <Center>
            <Checkbox
              value="checkbox"
              defaultIsChecked={checkboxRecipient}
              onChange={(state) => {
                dispatch(
                  setInputValue({ key: "checkboxRecipient", value: state })
                );
                if (state) {
                  dispatch(
                    setInputValue({
                      key: "recipientContactName",
                      value: fullName,
                    })
                  );
                  dispatch(
                    setInputValue({
                      key: "recipientContactEmail",
                      value: email,
                    })
                  );
                  dispatch(
                    setInputValue({
                      key: "recipientContactPhone",
                      value: phone,
                    })
                  );
                } else {
                  dispatch(
                    setInputValue({ key: "recipientContactName", value: "" })
                  );
                  dispatch(
                    setInputValue({ key: "recipientContactEmail", value: "" })
                  );
                  dispatch(
                    setInputValue({ key: "recipientContactPhone", value: "" })
                  );
                }
              }}
              accessibilityLabel="checkbox"
            />
          </Center>

          <Text style={styles.textContainer} fontSize="sm">
            Will the account owner be the recipient?
          </Text>
        </View>
        <Input
          value={recipientContactName}
          onChangeText={(value) => {
            dispatch(setInputValue({ key: "recipientContactName", value }));
          }}
          type="text"
          InputLeftElement={
            <View style={styles.leftIcon}>
              <User />
            </View>
          }
          placeholder="Full Name"
        />
        <View style={styles.divider} />
        <FormControl.Label>Recipient Email</FormControl.Label>
        <Input
          value={recipientContactEmail}
          onChangeText={(value) => {
            dispatch(setInputValue({ key: "recipientContactEmail", value }));
          }}
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          type="email"
          autoCorrect={false}
          autoCompleteType="email"
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Email />
            </View>
          }
          placeholder="Email Address"
        />
        <View style={styles.divider} />
        <FormControl.Label>Recipient Phone Number</FormControl.Label>
        {/* <Input
          type="tel"
          value={recipientContactPhone}
          onChangeText={(value) => {
            dispatch(setInputValue({ key: "recipientContactPhone", value }));
          }}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Phone />
            </View>
          }
          placeholder="Enter your Phone Number"
        /> */}

        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              borderRadius: 12,
              borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
              backgroundColor: isPhoneFocused ? "#E9F8EF" : "FAFAFA",
              borderWidth: 1,
              height: 46,
              width: "100%",
              paddingHorizontal: 12,
              outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
            },
          ]}
        >
          <View style={{ marginRight: 10 }}>
            <Phone />
          </View>
          <PhoneInput
            value={recipientContactPhone}
            onChange={(value) => {
              dispatch(setInputValue({ key: "recipientContactPhone", value }));
            }}
            type="phone"
            maxLength="14"
            placeholder="(222) 333-4455"
            defaultCountry="US"
            style={{
              paddingLeft: 0,
              outline: "none",
              flex: 1,
              borderWidth: 0,
              backgroundColor: isPhoneFocused ? "#E9F8EF" : "white",
            }}
            onFocus={() => setIsPhoneFocused(true)}
            onBlur={() => setIsPhoneFocused(false)}
          />
        </View>
        <View style={styles.emptySpace} />
        <BottomNavigator
          nextOnPress={nextStep}
          nextText={"Next"}
          prevOnPress={prevStep}
          prevText={"Previous"}
        />
      </View>
    </View>
  );
}
