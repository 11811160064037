// features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  loginAppleUser,
  loginGoogleUser,
  loginUser,
  loginUserByOtp,
  registerUser,
} from "./authApi";

const initialState = {
  loading: false,
  userToken: null, // for storing the JWT
  message: null,
  success: false, // for monitoring the registration process.
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      state.userInfo = {
        ...state.userInfo,
        ...action.payload,
      };
    },
    setToken(state, action) {
      state.userToken = action.payload;
      state.success = true;
    },
    logOutAction() {
      return initialState;
    },
    clearErrorMessage(state) {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload?.data?.message;
      state.userToken = action.payload?.accessToken;
      state.success = true;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message || "Wrong credentials";
    });

    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.userToken = null;
      state.message = null;
      state.success = false;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.userToken = action.payload?.accessToken;
      state.message = action.payload?.message;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.message = action?.payload?.data?.message || "Wrong credentials";
      state.loading = false;
      state.success = false;
    });

    builder.addCase(loginAppleUser.pending, (state) => {
      state.loading = true;
      state.userToken = null;
      state.message = null;
      state.success = false;
    });
    builder.addCase(loginAppleUser.fulfilled, (state, action) => {
      state.userToken = action.payload?.accessToken;
      state.message = action.payload?.message;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(loginAppleUser.rejected, (state, action) => {
      state.message = action?.payload?.data?.message || "Wrong credentials";
      state.loading = false;
      state.success = false;
    });

    builder.addCase(loginGoogleUser.pending, (state) => {
      state.loading = true;
      state.userToken = null;
      state.message = null;
      state.success = false;
    });
    builder.addCase(loginGoogleUser.fulfilled, (state, action) => {
      state.userToken = action.payload?.accessToken;
      state.message = action.payload?.message;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(loginGoogleUser.rejected, (state, action) => {
      state.message = action?.payload?.data?.message || "Wrong credentials";
      state.loading = false;
      state.success = false;
    });
  },
});

export const { logOutAction, setUser, clearErrorMessage, setToken } =
  authSlice.actions;

export default authSlice.reducer;
