import * as React from "react";
import {
  Button,
  Text,
  View,
  useToast,
  Box,
  Pressable,
} from "native-base";
import styles from "./styles";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";

export default function ConfirmCodeScreen() {
  const navigate = useNavigate();
  const { type, data } = useParams();

  const toast = useToast();
  const [code, setCode] = useState("");

  const verifyCode = async () => {
    const response = await axios
      .post(`${API_URL}/verification/verify`, {
        to: data,
        code,
      })
      .catch((err) => {
        toast.show({
          description: "Incorrect Verification Code",
          placement: "top",
        });
      });
    if (response) {
      navigate("/signup/user/1");
    }
  };

  const backToSignIn = () => {
    navigate("/signIn");
  };

  return (
    <Box style={styles.wrapper}>
      <Box style={styles.container}>
        <Text fontSize="2xl" bold pb={3} textAlign={"center"}>
          Enter verification code
        </Text>
        {type === "phone" && (
          <Text style={styles.subheader} pb={3}>
            A OTP has been sent to <Text bold> {data}</Text>. Kindly enter below
            the 6 digit code.
          </Text>
        )}
        {type === "email" && (
          <Text style={styles.subheader} pb={3}>
            Please enter the code we just sent to your email <Text bold>{data}</Text>
          </Text>
        )}

        <OtpInput
          value={code}
          onChange={setCode}
          numInputs={6}
          inputType="tel"
          containerStyle={{ display: "flex", justifyContent: "space-between" }}
          inputStyle={styles.inputOpt}
          shouldAutoFocus={1}
          renderInput={(props) => <input {...props} />}
        />

        <View style={{ marginTop: 20 }}>
          <Text fontSize="sm" textAlign={"center"}>
            Didn’t receive the OTP?{" "}
            <Text
              onPress={() => {
                const verificationData = {};
                if (type === "email") {
                  verificationData.email = data;
                }
                if (type === "phone") {
                  verificationData.phone = data;
                }
                axios.post(`${API_URL}/verification/${type}`, verificationData);
                toast.show({
                  description: "Sent new code",
                  placement: "top",
                });
                setCode("")
              }}
              style={styles.actionText}
            >
              Resend OTP
            </Text>
          </Text>
        </View>
        <View style={styles.buttonContainer}>
          <Button style={styles.button} onPress={verifyCode}>
            Continue
          </Button>
        </View>

        <Pressable
          textAlign={"center"}
          style={styles.actionText}
          onPress={backToSignIn}
        >
          Back to Sign In
        </Pressable>
      </Box>
    </Box>
  );
}
